<!--
 * @Author: lxiang
 * @Date: 2023-05-26 08:41:13
 * @LastEditors: lxiang
 * @LastEditTime: 2023-05-28 20:42:05
 * @description: 转账
 * @FilePath: \sea_mobile\src\views\web3\transfer.vue
-->
<template>
  <div>交易</div>
</template>
